import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";

const Result = () => {
  const [qr, setQr] = useState(localStorage.getItem("qr") || "");
  const navigate = useNavigate();

  useEffect(() => {
    if (!qr) {
      navigate("/");
    }
  }, [qr, navigate]);

  return (
    <div id="result">
      <Navbar />
      <Container>
        <div className="header-box my-4">
          <h1>Take the QR Code inside the UTGO</h1>
        </div>

        <div
          id="qr-code"
          className="p-5 d-flex align-items-center justify-content-center"
        >
          <QRCode value={qr} style={{ width: "250px" }} />
        </div>
      </Container>

      <Footer />
    </div>
  );
};

export default Result;
