import React, { useState, useRef, useEffect } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { API_URL } from "../App";

const Idle = () => {
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [screen, setScreen] = useState("scan_idle");
  const [isPlaying, setIsPlaying] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    handlePasswordSubmit();
  }, []);

  const handlePasswordSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("password", password);

      const response = await fetch(`${API_URL}/auth`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data.isAuthenticated) {
        setIsAuthenticated(true);
        setErrorMessage("");
      } else {
        if (isAuthenticated === null) setIsAuthenticated(false);
        else setErrorMessage(data.error || "Incorrect password");
      }
    } catch (error) {
      console.error("Authentication error:", error);
      setErrorMessage("An error occurred while authenticating.");
    }
  };

  // List of video sources
  const videoSources = [
    "scan_idle",
    "scan_error",
    "scan_repeat",
    "winner/1",
    "winner/2",
    "winner/3",
    "winner/4",
    "winner/5",
    "winner/6",
    "winner/7",
    "winner/8",
    "winner/9",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];

  useEffect(() => {
    if (inputRef.current && screen === "scan_idle") {
      inputRef.current.focus();
    }
  }, [screen, isAuthenticated]);

  const handleBlur = () => {
    if (inputRef.current && screen === "scan_idle") {
      inputRef.current.focus();
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (isPlaying) {
      inputRef.current.value = "";
      return;
    }

    const inputValue = inputRef.current.value;

    const form = new FormData();
    form.append("scan", inputValue);

    inputRef.current.value = "";

    console.log(inputValue);

    fetch(`${API_URL}/scan`, {
      method: "POST",
      body: form,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors.length > 0) {
          triggerError();
        } else if (!data.ok) {
          triggerRepeat();
        } else {
          const newReward = data.is_winner
            ? `winner/${data.collection}`
            : data.collection;

          setIsPlaying(true);
          setScreen(newReward);
        }
      })
      .catch((error) => console.error("Error posting data:", error));
  };

  const triggerError = () => {
    setIsPlaying(true);
    setScreen("scan_error");
  };

  const triggerRepeat = () => {
    setIsPlaying(true);
    setScreen("scan_repeat");
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
    setScreen("scan_idle");
  };

  if (isAuthenticated === null) return null;

  return (
    <>
      {!isAuthenticated ? (
        <Form className="text-center container d-flex align-items-center justify-content-center flex-column vh-100">
          <h3 className="display-1 fw-bold">
            Enter Password to Access Scan Page
          </h3>
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-3 fs-1"
            autoComplete="new-password"
          />
          <Button
            onClick={handlePasswordSubmit}
            className="mt-4 bg-red border-0 fs-1 px-5 py-2 fw-bold"
          >
            Submit
          </Button>
          {errorMessage && (
            <Alert variant="danger" className="mt-4 fs-2 px-5">
              {errorMessage}
            </Alert>
          )}
        </Form>
      ) : (
        <div id="idle" className="vh-100 vw-100 overflow-hidden">
          {/* Preload all videos */}
          <div style={{ display: "none" }}>
            {videoSources.map((video) => (
              <video
                key={video}
                preload="auto"
                src={`/assets/screens/${video}.mp4`}
              />
            ))}
          </div>

          {/* Active video player */}
          <video
            className="position-absolute top-0"
            autoPlay
            loop={screen === "scan_idle"}
            muted
            preload="auto"
            src={`/assets/screens/${screen}.mp4`}
            onEnded={screen === "scan_idle" ? null : handleVideoEnd}
          ></video>

          <form onSubmit={submitForm}>
            <input ref={inputRef} onBlur={handleBlur} />
          </form>
        </div>
      )}
    </>
  );
};

export default Idle;
