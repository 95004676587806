import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {
  Container,
  Table,
  Button,
  Modal,
  Form,
  Alert,
  Spinner,
} from "react-bootstrap";
import { format } from "date-fns";
import QRCode from "react-qr-code";
import { API_URL } from "../App";

const Admin = () => {
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showEntryModal, setShowEntryModal] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [email, setEmail] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [entries, setEntries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [playerInfo, setPlayerInfo] = useState(null);

  useEffect(() => {
    handlePasswordSubmit();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchEntries(); // Fetch latest 100 entries on successful authentication
    }
  }, [isAuthenticated]);

  const handlePasswordSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("password", password);

      const response = await fetch(`${API_URL}/auth`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (data.isAuthenticated) {
        setIsAuthenticated(true);
        setErrorMessage("");
      } else {
        if (isAuthenticated === null) setIsAuthenticated(false);
        else setErrorMessage(data.error || "Incorrect password");
      }
    } catch (error) {
      console.error("Authentication error:", error);
      setErrorMessage("An error occurred while authenticating.");
    }
  };

  // Fetch latest 100 entries
  const fetchEntries = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/operator/`);
      const data = await response.json();

      if (data) {
        setEntries(data.rows);
      } else {
        console.log(response);
        setErrorMessage("Failed to fetch entries");
      }
    } catch (error) {
      console.error("Error fetching entries:", error);
      setErrorMessage("An error occurred while fetching entries.");
    } finally {
      setLoading(false);
    }
  };

  // Search for specific entries
  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/operator/?q=${searchTerm}`);
      const data = await response.json();

      if (data) {
        setEntries(data.rows);
      } else {
        setErrorMessage("No matching records found");
      }
    } catch (error) {
      console.error("Search error:", error);
      setErrorMessage("An error occurred while searching.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch specific player information
  const fetchPlayer = async (type, entry) => {
    try {
      const response = await fetch(`${API_URL}/operator/player/${entry.id}`);
      const data = await response.json();

      if (data.ok) {
        setPlayerInfo(data.player);

        setModalType(type);
        setSelectedEntry(entry);
        setShowModal(true);
      } else {
        setErrorMessage("Failed to fetch player information");
      }
    } catch (error) {
      console.error("Error fetching player information:", error);
      setErrorMessage("An error occurred while fetching player information.");
    }
  };

  const handleShowModal = (type, entry) => {
    if (entry && entry.id) {
      fetchPlayer(type, entry);
    }
  };

  const handleSendCoupon = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("email", email);

      const response = await fetch(
        `${API_URL}/operator/player/${selectedEntry.id}/email`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();

      if (data.ok) {
        setShowModal(false);
      } else {
        setErrorMessage("Failed to send email.");
      }
    } catch (error) {
      setErrorMessage("An error occurred while sending the email.");
    } finally {
      setLoading(false);
    }

    setShowModal(false);
  };

  if (isAuthenticated === null) return null;

  return (
    <div id="instructions">
      <Navbar />
      <Container className="mt-5">
        {!isAuthenticated ? (
          <div className="text-center">
            <h3>Enter Password to Access Admin Page</h3>
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-3"
            />
            <Button
              onClick={handlePasswordSubmit}
              className="mt-3 bg-red border-0"
            >
              Submit
            </Button>
            {errorMessage && (
              <Alert variant="danger" className="mt-3">
                {errorMessage}
              </Alert>
            )}
          </div>
        ) : (
          <div>
            <h3>Latest 100 Entries</h3>

            <div className="d-flex align-items-center">
              <Form.Control
                type="text"
                placeholder="Search by full name or email"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="mt-3 mb-3"
              />
              <Button
                className="ms-3 bg-red border-0"
                onClick={handleSearch}
                disabled={loading}
              >
                {loading ? <Spinner animation="border" size="sm" /> : "Search"}
              </Button>
            </div>
            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

            {loading ? (
              <div className="text-center mt-5">
                <Spinner animation="border" />
              </div>
            ) : (
              <Table striped bordered hover className="mt-3">
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Timestamp</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {entries.map((entry) => (
                    <tr key={entry.id}>
                      <td>{entry.name}</td>
                      <td>
                        {format(new Date(entry.time_start * 1000), "HH:mm:ss")}
                      </td>
                      <td>{entry.status}</td>
                      <td>
                        <Button
                          variant="primary"
                          onClick={() => handleShowModal("qr", entry)}
                          className="me-2"
                          disabled={entry.status === "In Progress"}
                        >
                          <i className="fa fa-qrcode" />
                        </Button>
                        <Button
                          className="me-2"
                          variant="success"
                          onClick={() => handleShowModal("send", entry)}
                          disabled={entry.status === "In Progress"}
                        >
                          <i className="fa fa-envelope" />
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() => handleShowModal("coupon", entry)}
                          disabled={
                            entry.status === "In Progress" ||
                            entry.status === "Ready to Scan"
                          }
                        >
                          <i className="fa fa-ticket" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        )}
      </Container>
      <Footer />

      <Modal
        show={showEntryModal}
        onHide={() => setShowEntryModal(false)}
        centered
      ></Modal>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalType === "qr" && "View QR Code"}
            {modalType === "coupon" && "View Coupon"}
            {modalType === "send" && "Send Coupon"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalType === "qr" && (
            <div className="d-flex align-items-center justify-content-center p-3">
              <QRCode value={playerInfo.qr || ""} size={200} />
            </div>
          )}
          {modalType === "coupon" && (
            <h3>
              COUPON CODE:{" "}
              <span className="fw-normal">{playerInfo.voucher || "Error"}</span>
            </h3>
          )}
          {modalType === "send" && (
            <Form>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                onClick={handleSendCoupon}
                className="mt-3 bg-red border-0"
              >
                Send
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Admin;
