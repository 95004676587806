import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { ProgressBar, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { API_URL } from "../App";

const Quiz = () => {
  const [progress, setProgress] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const navigate = useNavigate();
  const [isQuizComplete, setIsQuizComplete] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [evaluatedDichotomies, setEvaluatedDichotomies] = useState([]);

  const location = useLocation();

  useEffect(() => {
    if (!location.state?.questions) {
      navigate("/form");
    }

    setQuestions(location.state?.questions);
  }, [location, navigate]);

  console.log(questions);

  const handleAnswer = (id, ending, dichotomyId) => {
    if (isQuizComplete) return;

    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers, { id, ending }];
      const nextIndex = currentQuestionIndex + 1;

      if (nextIndex < questions.length) {
        setCurrentQuestionIndex(nextIndex);

        setProgress((nextIndex / questions.length) * 100);

        setEvaluatedDichotomies((prevDichotomies) => [
          ...prevDichotomies,
          dichotomyId,
        ]);
      } else {
        setIsQuizComplete(true);
        setProgress(100);
      }

      console.log(updatedAnswers);

      return updatedAnswers;
    });
  };

  useEffect(() => {
    if (isQuizComplete) {
      console.log("Final Answers:", answers);
      const formAnswers = new FormData();
      answers.forEach((answer) => {
        formAnswers.append(`answers[${answer.id}]`, answer.ending);
      });

      fetch(`${API_URL}/end`, {
        method: "POST",
        body: formAnswers,
      })
        .then((response) => response.json())
        .then((data) => {
          if (!data.ok) {
            console.error("Unknown error attempting to post quiz.");
            navigate("/form");
            return;
          }

          localStorage.setItem("qr", data.qr);

          navigate("/result");
        })
        .catch((error) => console.error("Error posting data:", error));
    }
  }, [isQuizComplete, answers, navigate]);

  if (questions.length !== 4)
    return (
      <div className="vh-100 vw-100 d-flex align-items-center justify-content-center">
        <h1 className="display-1 text-red">Loading...</h1>
      </div>
    );

  return (
    <div id="quiz">
      <Navbar />
      <ProgressBar now={progress} className="rounded-0 my-3" />
      <Container className="header-box">
        {questions.length > 0 && (
          <h2>{questions[currentQuestionIndex].statement}</h2>
        )}
      </Container>
      {questions.length > 0 && (
        <QuestionGrid
          question={questions[currentQuestionIndex]}
          handleAnswer={handleAnswer}
          evaluatedDichotomies={evaluatedDichotomies}
        />
      )}
      <Footer />
    </div>
  );
};

const QuestionGrid = ({ question, handleAnswer, evaluatedDichotomies }) => {
  if (!question || !question.sets) {
    return <div>Loading question...</div>;
  }

  // Filter out evaluated dichotomies and select 2 random ones
  const availableSets = question.sets
    .filter((set) => !evaluatedDichotomies.includes(set.id_dichotomy))
    .sort(() => 0.5 - Math.random())
    .slice(0, 2);

  const rows = [];

  // Check if it's the last remaining dichotomy, in which case we show all pairs
  if (availableSets.length === 1) {
    const set = availableSets[0];
    set.options.forEach((option) => {
      const row = [
        {
          id: option.id,
          text: option.ending_positive,
          ending: 1,
          dichotomyId: set.id_dichotomy,
        },
        {
          id: option.id,
          text: option.ending_negative,
          ending: -1,
          dichotomyId: set.id_dichotomy,
        },
      ];
      rows.push(row);
    });
  } else {
    // If it's not the last dichotomy, select 2 random sets and create a row for each
    availableSets.forEach((set) => {
      const option =
        set.options[Math.floor(Math.random() * set.options.length)];

      const positiveChoice = {
        id: option.id,
        text: option.ending_positive,
        ending: 1,
        dichotomyId: set.id_dichotomy,
      };

      const negativeChoice = {
        id: option.id,
        text: option.ending_negative,
        ending: -1,
        dichotomyId: set.id_dichotomy,
      };

      const row = [positiveChoice, negativeChoice].sort(
        () => 0.5 - Math.random()
      );

      rows.push(row);
    });
  }

  return (
    <Container fluid>
      {rows.map((rowOptions, rowIndex) => (
        <Row key={rowIndex} className="g-1">
          {rowOptions.map(({ id, text, ending, dichotomyId }) => (
            <button
              xs={6}
              key={`${id}-${ending}`}
              onClick={() => handleAnswer(id, ending, dichotomyId)}
              className="col choice-item p-3"
            >
              <p className="text-black">{text}</p>
            </button>
          ))}
        </Row>
      ))}
    </Container>
  );
};

export default Quiz;
