const Footer = ({ monitor = false }) => {
  return (
    <footer
      style={{ height: "60px" }}
      className={`d-none bg-red z-1 position-fixed bottom-0 start-0 w-100 ${
        monitor && "p-3"
      }`}
    ></footer>
  );
};

export default Footer;
